<template>
    <v-row>
        <v-col>
             <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">

                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="dateValue"
                            :label="`Date ${optional ? '(optional)' : ''}`"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateValue"
                        @input="dateMenu = false"
                        @change="setChanged()">
                    </v-date-picker>
                </v-menu>
        </v-col>

        <v-col>
            <v-menu
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="`Time ${optional ? '(optional)' : ''}`"
                        v-model="timeValue"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                    v-model="timeValue"
                    full-width
                    use-seconds
                    format="24hr"
                    @input="setChanged()"
                    ></v-time-picker>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import moment from 'moment-timezone'
// import { rest } from '../_helpers'

export default {
    props: {
        datetime: String,
        optional: Boolean,
    },

    data() {
        return {
            dateMenu: false,
            timeMenu: false,
            datetimeObject: null,
        }
    },

    components: {
    },

    computed: {
        dateValue: {
            get() {
                return this.datetimeObject ? this.datetimeObject.format('YYYY-MM-DD') : null
            },
            set(val) {
                const dateSplit = val.split('-')

                if (this.datetimeObject) {
                    this.datetimeObject.set({
                        year: parseInt(dateSplit[0], 10),
                        month: parseInt(dateSplit[1], 10) - 1,
                        date: parseInt(dateSplit[2], 10),
                    })

                    this.datetimeObject = moment(this.datetimeObject)
                } else {
                    this.datetimeObject = moment(val, 'YYYY-MM-DD')
                }
            },
        },

        timeValue: {
            get() {
                return this.datetimeObject ? this.datetimeObject.format('HH:mm:ss') : null
            },
            set(val) {
                const timeSplit = val.split(':')

                if (this.datetimeObject) {
                    this.datetimeObject.set({
                        hour: parseInt(timeSplit[0], 10),
                        minute: parseInt(timeSplit[1], 10),
                        second: parseInt(timeSplit[2], 10),
                    })
                } else {
                    this.datetimeObject = moment().set({
                        hour: parseInt(timeSplit[0], 10),
                        minute: parseInt(timeSplit[1], 10),
                        second: parseInt(timeSplit[2], 10),
                    })
                }

                this.datetimeObject = moment(this.datetimeObject)
            },
        },
    },

    methods: {
        setChanged() {
            this.$emit('input-changed', this.datetimeObject)
        },
    },

    created() {
        this.datetimeObject = this.datetime ? moment(this.datetime) : null
    },

    watch: {
    },
}
</script>

<style scoped>

</style>
