import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":("Date " + (_vm.optional ? '(optional)' : '')),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateValue),callback:function ($$v) {_vm.dateValue=$$v},expression:"dateValue"}},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{on:{"input":function($event){_vm.dateMenu = false},"change":function($event){return _vm.setChanged()}},model:{value:(_vm.dateValue),callback:function ($$v) {_vm.dateValue=$$v},expression:"dateValue"}})],1)],1),_c(VCol,[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":("Time " + (_vm.optional ? '(optional)' : '')),"prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.timeValue),callback:function ($$v) {_vm.timeValue=$$v},expression:"timeValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[_c(VTimePicker,{attrs:{"full-width":"","use-seconds":"","format":"24hr"},on:{"input":function($event){return _vm.setChanged()}},model:{value:(_vm.timeValue),callback:function ($$v) {_vm.timeValue=$$v},expression:"timeValue"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }